import request from '@/utils/request'


// 查询猜你想搜列表
export function listGuessSearch(query) {
  return request({
    url: '/platform/guess-search/list',
    method: 'get',
    params: query
  })
}

// 查询猜你想搜分页
export function pageGuessSearch(query) {
  return request({
    url: '/platform/guess-search/page',
    method: 'get',
    params: query
  })
}

// 查询猜你想搜详细
export function getGuessSearch(data) {
  return request({
    url: '/platform/guess-search/detail',
    method: 'get',
    params: data
  })
}

// 新增猜你想搜
export function addGuessSearch(data) {
  return request({
    url: '/platform/guess-search/add',
    method: 'post',
    data: data
  })
}

// 修改猜你想搜
export function updateGuessSearch(data) {
  return request({
    url: '/platform/guess-search/edit',
    method: 'post',
    data: data
  })
}

// 删除猜你想搜
export function delGuessSearch(data) {
  return request({
    url: '/platform/guess-search/delete',
    method: 'post',
    data: data
  })
}
